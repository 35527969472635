





























import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import GoalSettingForm, { GoalFormData } from '@/components/organisms/GoalSettingForm.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { StudentGoal, Targetable } from '@/models/api/studentGoal'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import moment from 'moment'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    GoalSettingForm,
    ButtonBase,
  },
})
export default class GoalSetting extends Mixins(LocalMoment, LoadEnableSubjectsApi, ClassModeChangeAble) {
  private classModeTextByClassMode = ''

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  @Prop({ default: null })
  goalId?: number | null

  // 編集モード
  private get isEdit() {
    return this.goalId ? true : false
  }

  // 目標設定対象の定期テスト/講習会
  private targetable: Targetable | null = null

  // 期間選択が選択式であるか
  private get isSelectTargetAble() {
    return this.isTestMode || this.isSeminarMode
  }

  // みんなの目標
  private otherGoals: StudentGoal[] = []

  private formData: GoalFormData = {
    name: '',
    comment: '',
    from: '',
    to: '',
    targetableId: 0,
    subjectUnitType: 0,
    subjectScores: {},
    termSubjectScores: {},
  }

  // 理解度
  private readonly UNIT_TYPE_UNDERSTANDING = 3

  /**
   * フォームのバリデーション状態
   * 全項目入力済み & 教科入力蘭に0以上が入力されているか
   * 定期テストの場合は各教科の目標点数も含む
   */
  private get isSaveAble(): boolean {
    return this.permit(this.formData)
  }

  private permit(formData: GoalFormData): boolean {
    let permit = formData.name.length > 0 && formData.comment.length > 0

    if (this.isSelectTargetAble) {
      permit = permit && formData.targetableId != 0
    } else {
      permit = permit && formData.from.length > 0 && formData.to.length > 0
    }

    // 教科毎の入力状態
    if (formData.subjectUnitType == this.UNIT_TYPE_UNDERSTANDING) {
      // 理解度の場合は 1 ~ 100 までで入力されているか
      permit = permit && Object.values(formData.subjectScores).filter((i) => !(i && i > 0 && i <= 100)).length == 0
    } else {
      // 0以上が入力されているか
      permit =
        permit &&
        Object.values(formData.subjectScores).filter((i) => !(i && i > 0 && String(i).length <= 3)).length == 0
    }

    // 定期テストの場合、定期テストの目標点数の入力も条件に含める
    if (this.isTestMode) {
      permit = permit && Object.values(formData.termSubjectScores).filter((i) => !(i && i > 0 && i <= 100)).length == 0
    }

    return permit
  }

  private get colorTypeSaveBtn(): string {
    return this.isSaveAble ? 'gradation-orange' : 'pointer-events-none'
  }

  private onEditForm(newVal: GoalFormData) {
    this.formData = newVal
  }

  private onClickSave(): void {
    if (!this.permit(this.formData)) {
      alert('未入力箇所があります。')
      return
    }

    this.saveStudentGoal()
  }
  private onClickClear(): void {
    this.clearFormData()
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    this.classModeTextByClassMode = this.classModeText(this.currentClassMode)
    await this.loadEnableSubjects()
    await this.loadCurrentTargetable()
    if (this.isEdit) {
      await this.loadCurrentGoal()
    } else {
      this.setDefaultValuesFormData()
    }
    this.loadOtherGoals()
    Vue.prototype.$loading.complete()
  }

  // 目標設定対象の教科を取得
  private async loadEnableSubjects() {
    const subjects = await this.loadGdlsSubjectsApi(this.branchId, this.userId)
    subjects.forEach((subject) => {
      this.formData.subjectScores[subject.code] = null
      this.formData.termSubjectScores[subject.code] = null
    })
  }

  /**
   * 編集対象の目標情報取得
   */
  private async loadCurrentGoal() {
    if (this.goalId == null) return

    // 編集対象の目標情報取得
    const { data }: { data: StudentGoal } = await Vue.prototype.$http.httpWithToken.get(
      `/student_goals/${this.goalId}`,
      { params: { classModeCode: this.currentClassMode } }
    )

    this.formData.name = data.name
    this.formData.comment = data.comment
    this.formData.subjectUnitType = data.subjectUnitType

    if (this.isSelectTargetAble) {
      this.formData.targetableId = this.targetable?.id || 0
    } else {
      this.formData.from = moment(data.from).format('YYYY/MM/DD')
      this.formData.to = moment(data.to).format('YYYY/MM/DD')
    }
    data.studentGoalSubjects.forEach((i) => {
      if (i.subjectCode in this.formData.subjectScores) this.formData.subjectScores[i.subjectCode] = i.score
    })
    data.studentGoalTermExams?.forEach((i) => {
      if (i.subjectCode in this.formData.termSubjectScores) this.formData.termSubjectScores[i.subjectCode] = i.score
    })
  }

  private async loadCurrentTargetable() {
    if (!this.isSelectTargetAble) return

    const { data }: { data: Targetable } = await Vue.prototype.$http.httpWithToken.get(
      '/studentGoals/currentTargetable',
      {
        params: { classModeCode: this.currentClassMode },
      }
    )
    // 期間中の定期テスト/講習会
    this.targetable = data
  }

  /**
   * フォームデータクリア
   */
  private clearFormData() {
    this.formData.name = ''
    this.formData.comment = ''
    this.formData.to = ''
    this.formData.subjectUnitType = 0
    Object.keys(this.formData.subjectScores).forEach((key) => {
      this.formData.subjectScores[key] = null
    })
    Object.keys(this.formData.termSubjectScores).forEach((key) => {
      this.formData.termSubjectScores[key] = null
    })

    this.setDefaultValuesFormData()
  }

  /**
   * フォームデータにデフォルト値をセット
   */
  private setDefaultValuesFormData() {
    if (this.isSelectTargetAble) {
      // 定期テスト、講習会の場合、設定対象のIDと、デフォルト目標名のセット
      this.formData.targetableId = this.targetable?.id || 0
      this.formData.name = this.targetable?.name || ''
    } else {
      // 通常モードの場合、開始日の設定(当日)と、デフォルト目標名のセット
      this.formData.from = this.today().format('YYYY/MM/DD')
      this.formData.name = `${this.today().format('YYYY/MM/DD')}〜`
    }
  }

  /**
   * みんなの目標取得
   */
  private async loadOtherGoals() {
    const { data } = await Vue.prototype.$http.httpWithToken.get('/student_goals', {
      params: { classModeCode: this.currentClassMode, isOtherUsers: true },
    })
    this.otherGoals = data
  }

  /**
   * 目標作成リクエスト
   */
  private async saveStudentGoal() {
    Vue.prototype.$loading.start()
    const params = Object.assign(this.formData, { classModeCode: this.currentClassMode })
    if (this.isEdit) {
      // 更新
      await Vue.prototype.$http.httpWithToken
        .patch(`/student_goals/${this.goalId}`, params)
        .then(() => {
          alert('目標を更新しました。')
        })
        .catch((error: any) => {
          if (error?.response?.data?.status === 422) alert('目標は、当日中でなければ変更できません。')
        })
        .finally(() => {
          Vue.prototype.$loading.complete()
          this.$router.push('/student/goal-list')
        })
    } else {
      // 新規作成
      await Vue.prototype.$http.httpWithToken
        .post('/student_goals', params)
        .then(() => {
          alert('新規目標を作成しました。')
        })
        .catch((error: any) => {
          if (error?.response?.data?.status === 409) alert('既に作成済みの目標が存在します。')
        })
        .finally(() => {
          Vue.prototype.$loading.complete()
          this.$router.push('/student/goal-list')
        })
    }
  }
}
